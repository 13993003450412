<template>
  <div>
    <b-row
      class="mb-2"
      v-for="profile in fields.filter((el) => el.group_id == 1)"
      :key="'profile-fields-' + profile.id"
    >
      <b-col cols="4">
        <p class="text-title-input">
          {{ profile.name }}
          <span v-if="profile.required" class="text-danger">*</span>
        </p>
      </b-col>
      <b-col
        :set="(field = v.form[checkIsProfile(profile.field_profile_type_id)])"
        :id="'field-' + profile.profile_name"
      >
        {{ profile.validate_text }}
        <b-row v-if="profile.field_type_id == 1">
          <b-col>
            <InputText
              v-if="profile.field_type_id == 1"
              textFloat=""
              :placeholder="changeName(profile)"
              class="margin-inputf-regular"
              :type="checkFieldConditon(profile)"
              :name="profile.profile_name"
              className="mb-2"
              :isValidate="field.$error"
              :v="field"
              v-model="form[checkIsProfile(profile.field_profile_type_id)]"
            />
            <p
              class="text-right text-link mb-2"
              @click="addTel()"
              v-if="profile.field_profile_type_id == 10"
            >
              เบอร์โทรศัพท์สำรอง
            </p>
          </b-col>
        </b-row>
        <!-- <InputText
          v-if="profile.field_type_id == 1"
          textFloat=""
          :placeholder="changeName(profile)"
          class="margin-inputf-regular"
          :type="checkFieldConditon(profile)"
          :name="profile.profile_name"
          className="mb-2"
          :isValidate="field.$error"
          :v="field"
          v-model="form[checkIsProfile(profile.field_profile_type_id)]"
        /> -->

        <InputSelect
          v-else-if="profile.field_type_id == 16"
          title=""
          :text="profile.name"
          :name="profile.profile_name"
          :placeholder="profile.name"
          valueField="name"
          textField="display_name"
          v-bind:options="
            profile.field_choices
              ? profile.field_choices.filter((el) => el.is_display == 1)
              : []
          "
          v-model="form[checkIsProfile(profile.field_profile_type_id)]"
          :isRequired="profile.required"
          class="f-regular"
          :v="field"
          :validateText="profile.validate_text"
          :isValidate="field.$error"
          @change="(val) => changeLocale(val, true)"
          formType
        >
          <template v-slot:option-first>
            <b-form-select-option :value="null" disabled
              >-- {{ profile.name }} --</b-form-select-option
            >
          </template>
        </InputSelect>
        <b-row
          v-else-if="
            profile.field_profile_type_id == 2 ||
            profile.field_profile_type_id == 21
          "
        >
          <b-col>
            {{ profile.validate_text }}

            <InputSelect
              title=""
              :placeholder="profile.name"
              v-model="form[checkIsProfile(profile.field_profile_type_id)]"
              :options="
                profile.field_choices.map((x) => ({
                  text: x.display_name,
                  value: x.name,
                }))
              "
              @onDataChange="
                (val) =>
                  handleSelected(
                    val,
                    checkIsProfile(profile.field_profile_type_id),
                    profile.field_choices
                  )
              "
              :validateText="profile.validate_text"
              :isValidate="field.$error"
              :v="field"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="null" disabled
                  >-- {{ profile.name }} --</b-form-select-option
                >
              </template>
            </InputSelect>
          </b-col>

          <b-col v-if="isOtherPrefix && profile.field_profile_type_id == 2">
            <InputText
              className="mb-2"
              textFloat=""
              v-model="otherPrefix"
              :placeholder="
                profile.field_choices.find((x) => x.choice_type_id == 3)
                  .description
              "
            >
            </InputText
          ></b-col>

          <b-col
            v-else-if="isOtherGender && profile.field_profile_type_id == 21"
          >
            <InputText
              className="mb-2"
              textFloat=""
              v-model="otherGender"
              :placeholder="
                profile.field_choices.find((x) => x.choice_type_id == 3)
                  .description
              "
            >
            </InputText
          ></b-col>
        </b-row>
        <template v-else-if="profile.field_type_id == 7">
          <div class="datetime-container">
            <datetime
              type="date"
              :placeholder="profile.name"
              :class="[
                'date-picker-input',
                'date-filter',
                {
                  'border-red': field.$error,
                },
              ]"
              :format="'dd/MM/yyyy'"
              value-zone="Asia/Bangkok"
              v-model="form[checkIsProfile(profile.field_profile_type_id)]"
              @input="(val) => form[checkIsProfile(profile.field_profile_type_id)] = (val), "
              :maxDatetime="now"
              :validateText="field.validate_text"
              :isValidate="field.$error"
              :v="field"
              :isRequired="profile.required"
              :ref="'data-picker' + profile.name"
            >
            </datetime>
            <div class="calendar-icon">
              <font-awesome-icon icon="calendar-alt" />
            </div>
          </div>
          <span class="description text-error" v-if="field.$error">
            กรุณากรอกข้อมูล
          </span>
        </template>

        <div v-else-if="profile.field_type_id == 8">
          <label v-if="changeName(profile)">
            {{ changeName(profile) }}

            <span v-if="profile.required" class="text-danger">*</span>
          </label>

          <InputUploadImage
            :id="profile.id"
            label=""
            :img="form[checkIsProfile(profile.field_profile_type_id)]"
            v-model="form[checkIsProfile(profile.field_profile_type_id)]"
            :v="field"
            @setFileName="
              (val) => setImageName(val, profile.field_profile_type_id)
            "
            :validateText="field.validate_text"
            :isValidate="field.$error"
          />
          <div class="f-xs color-gray mt-1">
            ไฟล์ที่อัพโหลดต้องเป็นไฟล์นามสกุล PDF, PNG ,JPG ขนาดไม่เกิน 2.5 mb
            เท่านั้น
          </div>
          <span class="text-danger" v-if="field.$error">กรุณาอัพโหลดไฟล์</span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import InputUploadImage from "@/components/inputs/InputUploadImage.vue";
import InputTextArea from "@/components/inputs/InputTextArea";

import InputRadioGroup from "@/components/inputs/InputRadioGroup";
import { mapGetters } from "vuex";
import { fieldInForm2, profile_fields2 } from "@/assets/json/fieldProfile.js";

export default {
  components: {
    InputTextArea,
    OtherLoading,
    InputSelect,
    InputText,
    InputDatePickerFilter,
    InputRadioGroup,
    InputUploadImage,
  },
  name: "NewCustomer",
  props: {
    form: {
      type: Object,
      default: true,
    },
    v: {
      type: Object,
      default: true,
    },
    fields: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: true,
      now: null,
      isOtherPrefix: false,
      otherPrefix: "",
      isOtherGender: false,
      otherGender: "",
      isMember: false,
    };
  },
  watch: {
    otherPrefix(newVal) {
      this.$emit("otherPrefix", newVal, "prefix");
    },
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
    }),
  },
  created: async function () {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
    this.isLoading = false;
  },
  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    async setImageName(val, id) {
      let res = await this.checkIsProfile(id);
      if (res == "id_card_image") this.form.id_card_file_name = val;
    },

    changeName($fields) {
      return $fields.field_language[0]
        ? $fields.field_language[0].name
        : $fields.name;
    },
    checkFieldConditon(obj) {
      return obj.field_conditions[0] ? obj.field_conditions[0].value : "text";
    },
    checkIsProfile(val) {
      if (val == 99) val = 22;
      const findField = fieldInForm2.find((el) => el.id == val);
      return findField ? findField.key : false;
    },

    isNumber: function (e) {
      const maxlen = e.target.maxLength;
      const val = e.target.value;

      if (
        (e.key == "e" || e.charCode == 45 || e.charCode == 46) &&
        e.target.type == "number"
      ) {
        return e.preventDefault();
      }
      if (maxlen == -1) {
        return val;
      }

      if (val.length > maxlen - 1) {
        return e.preventDefault();
      }
    },
    checkFieldConditon(obj) {
      if (!obj.field_conditions) return "text";
      return obj.field_conditions[0] ? obj.field_conditions[0].value : "text";
    },

    handleSelected(val, field, options) {
      if (field == "prefix") {
        let other = options.find((x) => x.choice_type_id == 3).name;
        this.form.prefix = val;
        if (val == other) {
          this.isOtherPrefix = true;
        } else {
          this.otherPrefix = "";

          this.isOtherPrefix = false;
        }
      } else {
        let other = options.find((x) => x.choice_type_id == 3).name;
        this.form.gender = val;
        if (val == other) {
          this.isOtherGender = true;
        } else {
          this.otherGender = "";

          this.isOtherGender = false;
        }
      }
    },
    addTel() {
      this.$emit("AddOptionalTelephone");
    },
  },
};
</script>
<style scoped lang="scss">
.border-red {
  ::v-deep .vdatetime-input {
    border-color: red !important;
  }
}
.calendar-icon {
  position: absolute;
  top: 50%;
  right: 3%;
  transform: translateY(-50%);
}
.datetime-container {
  position: relative;
  margin-bottom: 0.5rem;
}
</style>
